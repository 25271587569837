import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { logException, logPageView } from "@/utils/app-insights";
import store from "../store/store";
import { ROLES_OWNER } from "@/models/constants";
import DevicesView from "../views/DevicesView.vue";
import { deleteCookie, getCookieValue, oktaSignIn } from "@/utils/auth-utils";
import { nextTick } from "vue";
import { WLOrg, WLUser } from "@/models/models";
import { api, apis, UnauthorizedError } from "@/api/api";
import { setSelectedOrg } from "@/utils/org-helper";

var isRouterInitialized = false;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/my/devices",
    meta: { requiresAuth: true },
  },
  {
    path: "/invite/:token",
    name: "invite",
    component: () => import("../views/AcceptInviteView.vue"),
    meta: { hideProfile: true },
    props: true,
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/UserProfileView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:shortName/subscribe",
    name: "subscribe",
    component: () => import("../views/billing/NewSubscriptionView.vue"),
    meta: { requiresAuth: true, unique: true, requiresOrgOwner: true },
  },
  {
    path: "/subscriptionsuccess/:shortName",
    name: "subscriptionsuccess",
    component: () => import("../views/billing/SubscriptionSuccessView.vue"),
    meta: { requiresAuth: true, hideProfile: true },
  },
  {
    path: "/apikeys",
    name: "apikeys",
    component: () => import("../views/orgs/APIKeysView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/orgs",
    name: "orgs",
    component: () => import("../views/orgs/OrgsView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:shortName/people",
    name: "people",
    component: () => import("../views/orgs/OrgUsers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/:shortName/collections",
    name: "collections",
    component: () => import("../views/CollectionsView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/devices",
    name: "devices",
    component: DevicesView,
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/search",
    name: "search",
    component: () => import("../views/SearchView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/analytics",
    name: "analytics",
    component: () => import("../views/AnalyticsView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/packages",
    name: "packages",
    component: () => import("../views/PackagesView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/integrations/webhooks",
    name: "webhooks",
    component: () => import("../views/integrations/WebhooksView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/integrations/azureeventhubs",
    name: "eventhubs",
    component: () => import("../views/integrations/AzureEventHubsView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/integrations/datacake",
    name: "datacake",
    component: () => import("../views/integrations/DatacakeView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/:shortName/integrations/azureeventgrid",
    name: "eventgrid",
    component: () => import("../views/integrations/AzureEventGridView.vue"),
    meta: { requiresAuth: true, unique: true },
    props: true,
  },
  {
    path: "/devoptions",
    name: "devoptions",
    component: () => import("../views/DevOptionsView.vue"),
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () => import("../views/NotFoundView.vue"),
  },
  {
    path: "/signout",
    name: "signout",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SignOutView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/signin",
    name: "signin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/sessionexpired",
    name: "sessionexpired",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SessionExpiredView.vue"),
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  
});


router?.beforeEach(async (to, _from, next) => {
  // didn't find a route match? 404.
  if (to.matched.length === 0) router.push("/notfound");

  const mcAuthValue = getCookieValue("mc_auth");
  if(!isRouterInitialized)
    {
      if (mcAuthValue !== null && mcAuthValue === "true") {

        try {
          await populateUserInfo();
        }
        catch (error) {
          if (error instanceof UnauthorizedError) {
            deleteCookie("mc_auth");
            oktaSignIn(`${window.location.origin}${to.fullPath}`);
          }
        }

        store.state.isAuthenticated = true;
        setSelectedOrg(to.params.shortName as string);
      }
      isRouterInitialized = true;
    }

  checkForAuth(to, mcAuthValue);

  showOrHideProfileWidget(to);

  checkForInvite(to);

  checkOrgRoutes(to);

  checkForOrgOwner(to, next);

  await checkForAdmin(to, next);
});
router?.afterEach((_from, _to) => {
  logPageView();
});

export default router;

const populateUserInfo = async () => {
  let user: WLUser;
  user = await api.get<WLUser>(`${apis.meadowCloud}/users/me`);
  store.state.user = user;

  try {
    store.commit(
      "cacheOrgs",
      await api.get<WLOrg[]>(`${apis.meadowCloud}/users/me/orgs`)
    );
  } catch (exception: any) {
    logException({ exception }, { userId: user.id });
  }
};

function checkForAuth(to, mcAuthValue)
{
  const requiresAuth = to?.meta?.requiresAuth;
  if(requiresAuth && (!store.state.isAuthenticated || !mcAuthValue)) {
      oktaSignIn(`${window.location.origin}${to.href}`);
  }
}
function showOrHideProfileWidget(to) {
  const profileIsHidden = to.matched.some((record) => record.meta.hideProfile);
  if (profileIsHidden) {
    store.state.display.profileIsHidden = true;
  } else {
    store.state.display.profileIsHidden = false;
  }
}

function checkForInvite(to) {
  if (to.name === "invite" && to.params?.token) {
    localStorage.setItem("invite", to.params?.token);
  }
}

function checkForOrgOwner(to, next) {
  const requiresOrgOwner = to.matched.some(
    (record) => record.meta.requiresOrgOwner
  );

  if (requiresOrgOwner) {
    if (store.state?.selectedOrg?.role !== ROLES_OWNER) {
      router.push("/notfound");
      next();
    }
  }
}

async function checkForAdmin(to, next) {
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  store.state.display.menuIsHidden = !store.state?.isAuthenticated;

  if (requiresAdmin) {
    if (store.state?.isAuthenticated) {
      await nextTick();
      const groups = store.state?.user?.mc_roles;
      const isAdmin = groups && groups.includes("mc_admin");
      if (isAdmin) {
        next();
      } else {
        next({ name: "notfound" }); // Redirect to a forbidden page if not an admin
      }
    } else {
      next({ name: "signin" }); // Redirect to signin page if not authenticated
    }
  } else {
    next();
  }
}

function checkOrgRoutes(to) {
  //toggle
  // if(to.name === "datacake" && !store.state.toggles?.showDataCake) {
  //   router.push("/notfound");
  //   return;
  // }

  if (!store.state?.selectedOrg) return;
  if (to.name === "invite") return;
  if (to.params?.shortName === "my") return;
  if (to.params?.shortName != store.state?.selectedOrg?.shortName) {
    const org = store.state?.orgs?.find(
      (org) => org.shortName === to.params?.shortName
    );
    if (org) {
      store.commit("setSelectedOrg", org);
    } else {
      if (to.name != "notfound" && to.meta.unique) router.push("/notfound");
    }
  }
}
